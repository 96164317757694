import { useNavigation, useSearchParams } from "react-router";
import React from "react";
import type { BookingPriceParams } from "utils/utils.ts";
import { useOptionalUser } from "./useUser.tsx";
export const useMarketplaceSearchBar = ({
  defaultService,
  defaultEndDate = null,
  defaultStartDate = null,
  defaultWasteType = null,
  defaultAddress = null,
  defaultPlaceId = null,
  defaultVolume = null,
  endDate = null,
  startDate = null,
  currentStep = 1,
}: {
  defaultService: number;
  defaultStartDate?: string | null;
  defaultEndDate?: string | null;
  defaultWasteType?: number | null;
  defaultVolume?: string | null;
  defaultAddress?: string | null;
  defaultPlaceId?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  currentStep?: 1 | 2 | 3 | 4;
}) => {
  const [searchParams] = useSearchParams();
  const transition = useNavigation();
  const isLoading = transition.state !== "idle";
  const user = useOptionalUser();
  // Search Params are always strings
  const isProfessionalDefined =
    searchParams.get("isProfessional") === "1" ||
    searchParams.get("isProfessional") === "0";
  const initialIsProfessional = isProfessionalDefined
    ? searchParams.get("isProfessional") === "1"
      ? "1"
      : "0"
    : // Default value
      "1";

  const startDateParam = searchParams.get("startDate")
    ? searchParams.get("startDate")
    : null;
  const endDateParam = searchParams.get("endDate")
    ? searchParams.get("endDate")
    : null;
  const serviceParam = searchParams.get("service")
    ? searchParams.get("service")
    : null;
  const addressParam = searchParams.get("address")
    ? searchParams.get("address")
    : null;
  const placeIdParam = searchParams.get("placeId")
    ? searchParams.get("placeId")
    : null;
  const wasteParam = searchParams.get("waste")
    ? searchParams.get("waste")
    : null;
  const volumeParam = searchParams.get("volume")
    ? searchParams.get("volume")
    : null;

  let stepParam = searchParams.get("step")
    ? (Number(searchParams.get("step")) as 1 | 2 | 3 | 4)
    : 1;

  if (
    stepParam !== 1 &&
    stepParam !== 2 &&
    stepParam !== 3 &&
    stepParam !== 4
  ) {
    stepParam = currentStep;
  }

  const [selectedFilters, setSelectedFilters] =
    React.useState<BookingPriceParams>(() => {
      return {
        currentStep: stepParam,
        service: serviceParam ?? defaultService?.toString(),
        volume: volumeParam ?? defaultVolume ?? "",
        address: addressParam ?? defaultAddress ?? "",
        placeId: placeIdParam ?? defaultPlaceId ?? "",
        waste: wasteParam ?? defaultWasteType?.toString() ?? null,
        startDate: startDate ?? startDateParam ?? defaultStartDate ?? "",
        endDate: endDate ?? endDateParam ?? defaultEndDate ?? "",
        userId: searchParams.get("userId") || user?.id,
        isRecurring: searchParams.get("isRecurring") === "1" ? "1" : "0",
        isProfessional: initialIsProfessional,
        plans: searchParams.get("plans") === "1" ? "1" : "0",
        immediatePickup:
          searchParams.get("immediatePickup") === "1" ? "1" : "0",
      };
    });

  React.useEffect(() => {
    if (!placeIdParam && !addressParam) return;
    if (
      selectedFilters.placeId !== placeIdParam ||
      selectedFilters.address !== addressParam
    ) {
      setSelectedFilters({
        ...selectedFilters,
        placeId: placeIdParam ?? "",
        address: addressParam ?? "",
      });
    }
  }, [addressParam, placeIdParam]);
  // const providerCountFetcher = useFetcher();
  const missingWasteFilter = !selectedFilters.waste;
  const missingVolumeFilter = !selectedFilters.volume;
  const missingAddressFilter =
    !selectedFilters.address || !selectedFilters.placeId;
  const missingFilter =
    missingWasteFilter || missingVolumeFilter || missingAddressFilter;
  const isSearchDisabled =
    isLoading || missingFilter || !selectedFilters.service;

  // React.useMemo(() => {
  //   if (typeof window === "undefined") return;

  //   if (!selectedFilters.waste || !selectedFilters.volume) {
  //     return;
  //   }
  //   const urlParams = new URLSearchParams();
  //   urlParams.set("volume", selectedFilters.volume);
  //   urlParams.set("waste", selectedFilters.waste);
  //   urlParams.set("service", selectedFilters.service);
  //   urlParams.set("address", selectedFilters.address);
  //   providerCountFetcher.load(
  //     `/solutions/providers-count?${urlParams.toString()}`
  //   );
  // }, [
  //   selectedFilters.waste,
  //   selectedFilters.volume,
  //   selectedFilters.service,
  //   selectedFilters.address,
  // ]);

  // const providerCount = providerCountFetcher?.data as
  //   | { count: number; show: boolean }
  //   | undefined;

  const hasClicked = searchParams.get("clicked") === "1";
  return {
    isSearchDisabled,
    missingFilter,
    missingAddressFilter,
    missingVolumeFilter,
    missingWasteFilter,
    selectedFilters,
    setSelectedFilters,
    isLoading,
    hasClicked,
  };
};
